import * as Types from '../types/graphql.gen';

import { gql } from '@apollo/client';
import { LoginNavigationUseHandleLoginNavigationLoginNavigationFragmentDoc } from '../loginNavigation/useHandleLoginNavigation.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PagesLoginMutationVariables = Types.Exact<{
  input: Types.LoginInput;
}>;


export type PagesLoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginNavigationLoginCompleted', redirectTo?: string | null } | { __typename?: 'LoginNavigationRequireChangeDefaultPassword', redirectTo?: string | null } | { __typename?: 'LoginNavigationRequireSMSAuthentication', redirectTo?: string | null } | { __typename?: 'LoginNavigationRequireSMSRecovery', redirectTo?: string | null } };

export type PagesLoginNavigationLoginNavigationLoginCompletedFragment = { __typename?: 'LoginNavigationLoginCompleted', redirectTo?: string | null };

export type PagesLoginNavigationLoginNavigationRequireChangeDefaultPasswordFragment = { __typename?: 'LoginNavigationRequireChangeDefaultPassword', redirectTo?: string | null };

export type PagesLoginNavigationLoginNavigationRequireSmsAuthenticationFragment = { __typename?: 'LoginNavigationRequireSMSAuthentication', redirectTo?: string | null };

export type PagesLoginNavigationLoginNavigationRequireSmsRecoveryFragment = { __typename?: 'LoginNavigationRequireSMSRecovery', redirectTo?: string | null };

export type PagesLoginNavigationFragment = PagesLoginNavigationLoginNavigationLoginCompletedFragment | PagesLoginNavigationLoginNavigationRequireChangeDefaultPasswordFragment | PagesLoginNavigationLoginNavigationRequireSmsAuthenticationFragment | PagesLoginNavigationLoginNavigationRequireSmsRecoveryFragment;

export const PagesLoginNavigationFragmentDoc = gql`
    fragment Pages_LoginNavigation on LoginNavigation {
  ...LoginNavigation_UseHandleLoginNavigation_LoginNavigation
}
    ${LoginNavigationUseHandleLoginNavigationLoginNavigationFragmentDoc}`;
export const PagesLoginDocument = gql`
    mutation Pages_Login($input: LoginInput!) {
  login(input: $input) {
    ...Pages_LoginNavigation
  }
}
    ${PagesLoginNavigationFragmentDoc}`;
export type PagesLoginMutationFn = Apollo.MutationFunction<PagesLoginMutation, PagesLoginMutationVariables>;

/**
 * __usePagesLoginMutation__
 *
 * To run a mutation, you first call `usePagesLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagesLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagesLoginMutation, { data, loading, error }] = usePagesLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePagesLoginMutation(baseOptions?: Apollo.MutationHookOptions<PagesLoginMutation, PagesLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PagesLoginMutation, PagesLoginMutationVariables>(PagesLoginDocument, options);
      }
export type PagesLoginMutationHookResult = ReturnType<typeof usePagesLoginMutation>;
export type PagesLoginMutationResult = Apollo.MutationResult<PagesLoginMutation>;
export type PagesLoginMutationOptions = Apollo.BaseMutationOptions<PagesLoginMutation, PagesLoginMutationVariables>;

/**
 * Alias (prefix removed)
 */
export type LoginNavigation = PagesLoginNavigationFragment
export const LoginNavigationFragmentDoc = PagesLoginNavigationFragmentDoc
export const useLoginMutation = usePagesLoginMutation
