import * as Types from '../types/graphql.gen';

import { gql } from '@apollo/client';
export type LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationLoginCompletedFragment = { __typename?: 'LoginNavigationLoginCompleted', redirectTo?: string | null };

export type LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationRequireChangeDefaultPasswordFragment = { __typename?: 'LoginNavigationRequireChangeDefaultPassword', redirectTo?: string | null };

export type LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationRequireSmsAuthenticationFragment = { __typename?: 'LoginNavigationRequireSMSAuthentication', redirectTo?: string | null };

export type LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationRequireSmsRecoveryFragment = { __typename?: 'LoginNavigationRequireSMSRecovery', redirectTo?: string | null };

export type LoginNavigationUseHandleLoginNavigationLoginNavigationFragment = LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationLoginCompletedFragment | LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationRequireChangeDefaultPasswordFragment | LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationRequireSmsAuthenticationFragment | LoginNavigationUseHandleLoginNavigationLoginNavigationLoginNavigationRequireSmsRecoveryFragment;

export const LoginNavigationUseHandleLoginNavigationLoginNavigationFragmentDoc = gql`
    fragment LoginNavigation_UseHandleLoginNavigation_LoginNavigation on LoginNavigation {
  ... on LoginNavigationLoginCompleted {
    redirectTo
  }
  ... on LoginNavigationRequireChangeDefaultPassword {
    redirectTo
  }
  ... on LoginNavigationRequireSMSAuthentication {
    redirectTo
  }
  ... on LoginNavigationRequireSMSRecovery {
    redirectTo
  }
}
    `;

/**
 * Alias (prefix removed)
 */
export type LoginNavigation = LoginNavigationUseHandleLoginNavigationLoginNavigationFragment
export const LoginNavigationFragmentDoc = LoginNavigationUseHandleLoginNavigationLoginNavigationFragmentDoc

