import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { pagesPath } from 'src/routes/$path'
import { LoginNavigation } from './useHandleLoginNavigation.gen'

// ログインプロセス中の遷移・ログイン完了後の遷移を制御します
// 遷移先は、バックエンドから返却される LoginNavigation の情報に従います
export const useHandleLoginNavigation = () => {
  const router = useRouter()

  // サイト内なら router.push を使う
  const navigate = useCallback(
    (url: string) => {
      if (url.startsWith(location.origin)) {
        router.push(url)
      } else {
        window.location.href = url
      }
    },
    [router],
  )

  const handleLoginNavigation = useCallback(
    (navigation?: LoginNavigation | null) => {
      if (navigation) {
        if (navigation.__typename === 'LoginNavigationLoginCompleted') {
          if (navigation.redirectTo) {
            navigate(navigation.redirectTo)
            return
          }
        }
        if (navigation.__typename === 'LoginNavigationRequireChangeDefaultPassword') {
          if (navigation.redirectTo) {
            navigate(navigation.redirectTo)
            return
          }
          return
        }
        if (navigation.__typename === 'LoginNavigationRequireSMSAuthentication') {
          if (navigation.redirectTo) {
            navigate(navigation.redirectTo)
            return
          }
          return
        }
        if (navigation.__typename === 'LoginNavigationRequireSMSRecovery') {
          if (navigation.redirectTo) {
            navigate(navigation.redirectTo)
            return
          }
          return
        }
      }

      router.push(pagesPath.$404.$url())
    },
    [router, navigate],
  )

  return handleLoginNavigation
}
